// Klient testowy
// klient@miastotogra.pl
// miastotogra

<template>
  <div id="client-login">
    <img src="/home/logo-white.png" width="200">
    <h1>Panel klienta</h1>
    <form @submit.prevent="login">
      <input type="email" v-model="email" placeholder="E-mail"/>
      <input type="password" v-model="password" placeholder="Hasło"/>
      <button type="submit">Zaloguj</button>
    </form>
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
  data() {
    return {
      email: "",
      password: "",
      clientsIds: []
    };
  },
  mounted() {
    const db = firebase.firestore();
    db.collection("clients").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          this.clientsIds.push(doc.id);
          // console.log(doc.id, " => ", doc.data());
      });
      this.checkLogin();
    });
  },
  methods: {
    checkLogin() {
      firebase.auth().onAuthStateChanged(user =>  {
        let foundClient = -1;
        if (user)
          foundClient = this.clientsIds.findIndex(id => id == user.uid);
        
        if (foundClient >= 0) {
          this.$router.push('/client/dashboard');
          this.$store.commit('SET_USER', user);
        }
      });
    },
    login() {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
        let foundClient = this.clientsIds.findIndex(id => id == user.user.uid);
        
        if (foundClient >= 0) {
          console.log("Logowanie...");
          this.$router.push('/client/dashboard');
          this.$store.commit('SET_USER', user);
        } else {
          firebase.auth().signOut().then(() => {
            this.$store.commit('LOGOUT_USER');
            this.email = "";
            this.password = "";
            alert("Twoje konto nie posiada odpowiednich uprawnień.");
          });
        }
      }).catch(error => {
        this.password = "";
        alert(error.message);
      });
    }
  }
};
</script>

<style scoped>
#client-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
}
h1 {
  margin-top: 2rem;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
}
form {
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 400px;
}
form * {
  margin: .5rem 0;
}
input {
  padding: .5rem;
  border-radius: .25rem;
  border: none;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.25);
  font-family: 'Oswald', sans-serif;
}
button {
  border-radius: .25rem;
  background: #00aeff;
  box-shadow: 0 0 20px 0 rgb(0, 0, 0, 0.25);
}
</style>